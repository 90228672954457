function ResourceEventRenderer() {
	var t = this;

	// exports
	t.renderEvents = renderEvents;
	t.clearEvents = clearEvents;
	t.slotSegHtml = slotSegHtml;

	t.clientSlotSegments = function () {
		return getClientSegments(clientDaySegments, clientSlotSegments);
	};

	// imports
	ResourceDayEventRenderer.call(t);
	var opt = t.opt;
	var trigger = t.trigger;
	var isPreventEventMovement = t.isPreventEventMovement;
	var isEventDraggable = t.isEventDraggable;
	var isEventResizable = t.isEventResizable;
	var eventElementHandlers = t.eventElementHandlers;
	var setHeight = t.setHeight;
	var getDaySegmentContainer = t.getDaySegmentContainer;
	var getSlotSegmentContainer = t.getSlotSegmentContainer;
	var getHoverListener = t.getHoverListener;
	var computeDateTop = t.computeDateTop;
	var getIsCellAllDay = t.getIsCellAllDay;
	var colContentLeft = t.colContentLeft;
	var colContentRight = t.colContentRight;
	var cellToDate = t.cellToDate;
	var getColCnt = t.getColCnt;
	var getColWidth = t.getColWidth;
	var getSnapHeight = t.getSnapHeight;
	var getSnapDuration = t.getSnapDuration;
	var getSlotHeight = t.getSlotHeight;
	var getSlotDuration = t.getSlotDuration;
	var getSlotContainer = t.getSlotContainer;
	var reportEventElement = t.reportEventElement;
	var showEvents = t.showEvents;
	var hideEvents = t.hideEvents;
	var eventDrop = t.eventDrop;
	var eventCancelDrop = t.eventCancelDrop;
	var eventResize = t.eventResize;
	var renderDayOverlay = t.renderDayOverlay;
	var clearOverlays = t.clearOverlays;
	var renderDayEvents = t.renderDayEvents;
	var getMinTime = t.getMinTime;
	var getMaxTime = t.getMaxTime;
	var calendar = t.calendar;
	var formatDate = calendar.formatDate;
	var formatRange = calendar.formatRange;
	var getEventEnd = calendar.getEventEnd;

	//Customized for SeedCode
	var getResources = t.getResources;
	var resourceDays = opt('resourceDays');
	var manageClone = t.manageClone;

	var clientDaySegments;
	var clientSlotSegments;
	var segmentInstanceMap;

	// overrides
	t.draggableDayEvent = draggableDayEvent;

	/* Rendering
	----------------------------------------------------------------------------*/

	function renderEvents(events, modifiedEventId) {
		var eventShownFunc = opt('eventShown');
		var showEvent;
		var i,
			len = events.length,
			dayEvents = [],
			slotEvents = [];
		for (i = 0; i < len; i++) {
			var beforeRenderResult = trigger(
				'beforeEventRender',
				events[i],
				events[i],
				events
			);
			if (beforeRenderResult) {
				continue;
			}
			if (
				eventShownFunc(events[i]) &&
				!events[i].unscheduled &&
				!events[i].isMeasureOnly &&
				!events[i].isMapOnly
			) {
				if (
					events[i].allDay &&
					events[i].resource &&
					!events[i].isUnavailable
				) {
					dayEvents.push(events[i]);
				} else if (events[i].resource) {
					slotEvents.push(events[i]);
				}
			}
		}

		if (opt('allDaySlot')) {
			clientDaySegments = renderDayEvents(dayEvents, modifiedEventId);
			setHeight(); // no params means set to viewHeight
		}

		clientSlotSegments = renderSlotSegs(
			compileSlotSegs(slotEvents),
			modifiedEventId
		);
	}

	function clearEvents() {
		getDaySegmentContainer().empty();
		getSlotSegmentContainer().empty();
	}

	function compileSlotSegs(events) {
		segmentInstanceMap = {};

		var colCnt = getColCnt(),
			minTime = getMinTime(),
			maxTime = getMaxTime(),
			cellDate,
			i,
			j,
			seg,
			colSegs,
			segs = [];

		for (var days = 0; days < resourceDays; days++) {
			for (i = 0; i < colCnt; i++) {
				cellDate = cellToDate(0, days);

				colSegs = sliceSegs(
					events,
					cellDate.clone().time(minTime),
					cellDate.clone().time(maxTime),
					getResources(false)[i]
				);
				colSegs = placeSlotSegs(colSegs); // returns a new order

				for (j = 0; j < colSegs.length; j++) {
					seg = colSegs[j];
					seg.col = i + days * colCnt;
					segs.push(seg);
				}
			}
		}

		return segs;
	}

	function sliceSegs(events, rangeStart, rangeEnd, resource) {
		// normalize, because all dates will be compared w/o zones
		rangeStart = rangeStart.clone().stripZone();
		rangeEnd = rangeEnd.clone().stripZone();

		var segs = [],
			i,
			len = events.length,
			event,
			eventStart,
			eventEnd,
			segStart,
			segEnd,
			isStart,
			isEnd;
		for (i = 0; i < len; i++) {
			event = events[i];

			// get dates, make copies, then strip zone to normalize
			eventStart = event.start.clone().stripZone();
			eventEnd = getEventEnd(event).stripZone();
			if (
				(eventEnd > rangeStart && eventStart < rangeEnd) ||
				(eventEnd.valueOf() === rangeStart.valueOf() &&
					eventEnd.valueOf() === eventStart.valueOf())
			) {
				if (eventStart < rangeStart) {
					segStart = rangeStart.clone();
					isStart = false;
				} else {
					segStart = eventStart;
					isStart = true;
				}

				if (eventEnd > rangeEnd) {
					segEnd = rangeEnd.clone();
					isEnd = false;
				} else {
					segEnd = eventEnd;
					isEnd = true;
				}

				//If we have a match to a resource then we apply the event to the appropriate segment.
				if (event.resource.length) {
					for (var ii = 0; ii < event.resource.length; ii++) {
						if (event.resource[ii] === resource.name) {
							if (segmentInstanceMap[event._id] === undefined) {
								segmentInstanceMap[event._id] = 0;
							} else {
								segmentInstanceMap[event._id]++;
							}

							segs.push({
								event: event,
								start: segStart,
								end: segEnd,
								isStart: isStart,
								isEnd: isEnd,
								instance: segmentInstanceMap[event._id],
							});
						}
					}
				}
			}
		}

		return segs.sort(compareSlotSegs);
	}

	// renders events in the 'time slots' at the bottom
	// TODO: when we refactor this, when user returns `false` eventRender, don't have empty space
	// TODO: refactor will include using pixels to detect collisions instead of dates (handy for seg cmp)

	function renderSlotSegs(segs, modifiedEventId) {
		var i,
			segCnt = segs.length,
			seg,
			event,
			top,
			bottom,
			columnLeft,
			columnRight,
			columnWidth,
			width,
			left,
			right,
			html = '',
			eventElements,
			eventElement,
			triggerRes,
			titleElement,
			height,
			slotSegmentContainer = getSlotSegmentContainer(),
			isRTL = opt('isRTL');

		//Customized for SeedCode
		var verticalPadding;

		// calculate position/dimensions, create html
		for (i = 0; i < segCnt; i++) {
			seg = segs[i];
			event = seg.event;
			top = computeDateTop(seg.start, seg.start);
			bottom = computeDateTop(seg.end, seg.start);
			columnLeft = colContentLeft(seg.col);
			columnRight = colContentRight(seg.col);
			columnWidth = columnRight - columnLeft;

			//Customized for SeedCode
			verticalPadding = 2;

			if (event.isDayback || event.isUnavailable) {
				top += 1;
				columnLeft -= verticalPadding;
				columnRight += verticalPadding;
				verticalPadding = 0;
			} else {
				// shave off space on right near scrollbars (2.5%)
				// TODO: move this to CSS somehow
				columnRight -= columnWidth * 0.025;
			}

			columnWidth = columnRight - columnLeft;

			width = columnWidth * (seg.forwardCoord - seg.backwardCoord);

			if (opt('slotEventOverlap')) {
				// double the width while making sure resize handle is visible
				// (assumed to be 20px wide)
				width = Math.max(
					(width - 20 / 2) * 2,
					width // narrow columns will want to make the segment smaller than
					// the natural width. don't allow it
				);
			}

			if (isRTL) {
				right = columnRight - seg.backwardCoord * columnWidth;
				left = right - width;
			} else {
				left = columnLeft + seg.backwardCoord * columnWidth;
				right = left + width;
			}

			// make sure horizontal coordinates are in bounds
			left = Math.max(left, columnLeft);
			right = Math.min(right, columnRight);
			width = right - left;

			if (event.isUnavailable) {
				left = columnLeft;
				right = columnRight;
				width = columnWidth;
				verticalPadding = 0;
			}

			seg.top = top + verticalPadding;
			seg.left = left;
			seg.outerWidth = width;
			seg.outerHeight = bottom - top - verticalPadding * 2;
			html += slotSegHtml(event, seg);
		}

		slotSegmentContainer[0].innerHTML = html; // faster than html()
		eventElements = slotSegmentContainer.children();

		// retrieve elements, run through eventRender callback, bind event handlers
		for (i = 0; i < segCnt; i++) {
			seg = segs[i];
			event = seg.event;
			eventElement = $(eventElements[i]); // faster than eq()
			triggerRes = trigger('eventRender', event, event, eventElement);
			if (triggerRes === false) {
				eventElement.remove();
			} else {
				if (triggerRes && triggerRes !== true) {
					eventElement.remove();
					eventElement = $(triggerRes)
						.css({
							position: 'absolute',
							top: seg.top,
							left: seg.left,
						})
						.appendTo(slotSegmentContainer);
				}
				seg.element = eventElement;
				if (event._id === modifiedEventId) {
					bindSlotSeg(event, eventElement, seg);
				} else {
					eventElement[0]._fci = i; // for lazySegBind
				}
				reportEventElement(event, eventElement);
			}
		}

		lazySegBind(slotSegmentContainer, segs, bindSlotSeg);

		// record event sides and title positions
		for (i = 0; i < segCnt; i++) {
			seg = segs[i];
			if ((eventElement = seg.element)) {
				seg.vsides = vsides(eventElement, true);
				seg.hsides = hsides(eventElement, true);
				titleElement = eventElement.find('.fc-event-title');
				if (titleElement.length) {
					seg.contentTop = titleElement[0].offsetTop;
				}
			}
		}

		// set all positions/dimensions at once
		for (i = 0; i < segCnt; i++) {
			seg = segs[i];
			if ((eventElement = seg.element)) {
				eventElement[0].style.width =
					Math.max(0, seg.outerWidth - seg.hsides) + 'px';
				height = Math.max(0, seg.outerHeight - seg.vsides);
				eventElement[0].style.height = height + 'px';
				event = seg.event;
				if (
					seg.contentTop !== undefined &&
					height - seg.contentTop < 10
				) {
					// not enough room for title, put it in the time (TODO: maybe make both display:inline instead)
					eventElement
						.find('div.fc-event-time')
						.html(
							formatDate(event.start, opt('timeFormat')) + ' -'
						);
					eventElement
						.find('div.fc-event-title')
						.addClass('event-title-inline');
				}
				trigger('eventAfterRender', event, event, eventElement);
			}
		}

		return segs;
	}

	function slotSegHtml(event, seg) {
		var html = '<';
		var url = event.url;
		var skinCss = getSkinCss(event, opt);
		var classes = ['fc-event', 'fc-event-vert'];
		if (isEventDraggable(event)) {
			classes.push('fc-event-draggable');
		}
		if (event.isUnavailable) {
			classes.push('fc-event-unavailable');
		}
		if (event.isDayback) {
			classes.push('fc-event-dayback');
			if (seg.outerHeight > 50 && seg.outerWidth > 100) {
				classes.push('fc-event-dayback-lg');
			}
		}
		if (seg.isStart) {
			classes.push('fc-event-start');
		}
		if (seg.isEnd) {
			classes.push('fc-event-end');
		}
		classes = classes.concat(event.className);
		if (event.source) {
			classes = classes.concat(event.source.className || []);
		}
		if (url) {
			html += "a href='" + htmlEscape(event.url) + "'";
		} else {
			html += 'div';
		}

		html +=
			" data-id='" +
			event._id +
			"'" +
			" data-instance='" +
			seg.instance +
			"'" +
			" class='" +
			classes.join(' ') +
			"'" +
			' style=' +
			"'" +
			'position:absolute;' +
			'top:' +
			seg.top +
			'px;' +
			'left:' +
			seg.left +
			'px;' +
			skinCss +
			"'" +
			'>';
		if (seg.isStart && isEventResizable(event)) {
			html +=
				"<div class='ui-resizable-handle ui-resizable-n" +
				"'>" +
				'</div>';
		}
		html += "<div class='fc-event-inner'>";

		if (!event.isUnavailable) {
			html += "<div class='fc-event-time time'>";

			if (event.end) {
				html += htmlEscape(
					formatRange(event.start, event.end, opt('timeFormat'))
				);
			} else {
				html += htmlEscape(formatDate(event.start, opt('timeFormat')));
			}

			html += '</div>';
		}

		html +=
			"<div class='fc-event-title'>" +
			titleEscape(event.title || '', event) +
			'</div>' +
			'</div>' +
			"<div class='fc-event-bg'></div>";

		if (seg.isEnd && isEventResizable(event)) {
			html += "<div class='ui-resizable-handle ui-resizable-s'>=</div>";
		}
		html += '</' + (url ? 'a' : 'div') + '>';
		return html;
	}

	function bindSlotSeg(event, eventElement, seg) {
		var timeElement = eventElement.find('div.fc-event-time');
		if (isEventDraggable(event)) {
			draggableSlotEvent(event, eventElement, timeElement);
		} else {
			nonDraggableDayEvent(event, eventElement, timeElement);
		}

		if (isEventResizable(event)) {
			resizableSlotEvent(event, eventElement, timeElement);
		}
		eventElementHandlers(event, eventElement);
	}

	/* Dragging
	-----------------------------------------------------------------------------------*/

	function boundaryModeDragCheck(options, boundaryMode) {
		var showAltView = opt('showAltView');

		if (
			!options.boundaryMode &&
			options.direction > 0 &&
			options.allowBoundaryMovement &&
			showAltView &&
			options.ev.pageX >
				options.containerPosition.right - options.colWidth / 2
		) {
			boundaryMode = true;
			options.eventElement.draggable('option', 'grid', [
				1,
				options.snapHeight,
			]);
			options.eventElement.draggable('option', 'axis', false);
		} else if (
			boundaryMode &&
			options.ev.pageX <
				options.containerPosition.right - options.colWidth / 2
		) {
			boundaryMode = false;
			options.eventElement.draggable('option', 'grid', [
				options.colWidth,
				options.snapHeight,
			]);
			options.eventElement.draggable(
				'option',
				'axis',
				options.colCnt == 1 ? 'y' : false
			);
		} else if (
			!options.isInBounds &&
			options.prevIsInBounds &&
			options.colCnt > 1
		) {
			options.eventElement.draggable('option', 'grid', [1, 1]);
		} else if (
			options.isInBounds &&
			!options.prevIsInBounds &&
			options.colCnt > 1
		) {
			options.eventElement.draggable('option', 'grid', [
				options.colWidth,
				options.snapHeight,
			]);
		}
		return boundaryMode;
	}

	// when event starts out FULL-DAY
	// overrides DayEventRenderer's version because it needs to account for dragging elements
	// to and from the slot area.

	function draggableDayEvent(event, eventElement, seg) {
		var isStart = seg.isStart;
		var origWidth;
		var revert;
		var allDay = true;
		var dayDelta;

		var hoverListener = getHoverListener();
		var colWidth = getColWidth();
		var minTime = getMinTime();
		var slotDuration = getSlotDuration();
		var slotHeight = getSlotHeight();
		var snapDuration = getSnapDuration();
		var snapHeight = getSnapHeight();
		var coordinateGrid = t.getCoordinateGrid();

		//Customized for SeedCode to work with multi day resource view
		var colCnt = getColCnt();
		var originalEventResource;
		var eventResource;
		var newEventResource = event.resource.slice(0); //Clone the resource array
		var colChanged;

		var eventPosition;
		var isInBounds, prevIsInBounds;

		var container = $('.fc-view > div');
		var containerPosition = container[0].getBoundingClientRect();
		var contentContainer = container[0].querySelector(
			'.fc-event-container'
		);

		var slotContainer = $('.calendar-scroll');
		var slotContainerPosition = slotContainer[0].getBoundingClientRect();
		var isAltViewDrag;
		var altViewHover;
		var startScroll;
		var boundaryMode;
		var startPageX;

		eventElement.draggable({
			opacity: opt('dragOpacity', 'month'), // use whatever the month view was using
			revertDuration: opt('dragRevertDuration'),
			helper: 'clone',
			start: function (ev, ui) {
				if (isPreventEventMovement(event)) {
					eventElement.css('opacity', 1);
					return false;
				}

				isClone = isClone = manageClone(ev, ui, event);
				if (isClone) {
					ui.helper.append(
						'<div class="add-event-button add-event-button-small"></div>'
					);
				} else {
					eventElement.css('visibility', 'hidden');
				}
				timeElement = ui.helper.find('div.fc-event-time');

				startPageX = ev.pageX;
				startScroll = container.scrollTop();
				calendar.reportAltViewDragStart(ev, ui); // For dragging from calendar into unscheduled
				trigger('eventDragStart', eventElement, event, ev, ui);
				//We don't want to hide duplicate instances of this event (would be this way for repeating events or for multiple resources selected)
				//hideEvents(event, eventElement);
				origWidth = eventElement.width();
				hoverListener.start(
					function (cell, origCell) {
						clearOverlays();
						if (cell) {
							isInBounds = true;
							revert = false;

							var origDate = cellToDate(
								0,
								Math.floor(origCell.col / colCnt)
							);
							var date = cellToDate(
								0,
								Math.floor(cell.col / colCnt)
							);
							//var date = cellToDate(0, Math.floor(origCell.col / colCnt)); //fix - is this needed, can we delete it
							// var colDelta = origCell.col - cell.col;
							colChanged = origCell.col !== cell.col;
							dayDelta = date.diff(origDate, 'days');
							originalEventResource =
								getResources(false)[origCell.col % colCnt];
							eventResource =
								getResources(false)[cell.col % colCnt];

							//Slot style -- delete after integration of new date math
							//col = Math.min((colCnt * resourceDays) - 1, col);
							//End slot style
							if (!cell.row) {
								// on full-days
								renderDayOverlay(
									event.start.clone().add(dayDelta, 'days'),
									getEventEnd(event).add(dayDelta, 'days'),
									[eventResource.name]
								);

								resetElement(ui);
							} else {
								// mouse is over bottom slots
								if (isStart) {
									if (allDay) {
										// convert event to temporary slot-event
										ui.helper.width(colWidth - 10); // don't use entire width
										setOuterHeight(
											ui.helper,
											(calendar.defaultTimedEventDuration /
												slotDuration) *
												slotHeight
										); // the default height
										eventElement.draggable(
											'option',
											'grid',
											[colWidth, 1]
										);
										allDay = false;
									}
								} else {
									revert = true;
								}
							}

							revert =
								revert || (allDay && !colChanged && !isClone);
						} else {
							isInBounds = false;
							resetElement(ui);
							revert = true;
						}
						eventElement.draggable('option', 'revert', revert);
					},
					ev,
					'drag'
				);
			},
			drag: function (ev, ui) {
				var cell = coordinateGrid.cell(ev.pageX, ev.pageY);
				var direction = !startPageX
					? 0
					: ev.pageX > startPageX
						? 1
						: -1;
				var allowBoundaryMovement =
					ev.pageX - startPageX > colWidth / 3;

				isInBounds = !!cell && ev.pageY > slotContainerPosition.top;
				isClone = manageClone(ev, ui, event);
				isAltViewDrag = calendar.reportAltViewDrag(
					ev,
					ui,
					contentContainer,
					event,
					isClone
				);
				if (isAltViewDrag) {
					ui.position.top =
						ui.position.top + containerPosition.top - startScroll;
					ui.position.left =
						ui.position.left + containerPosition.left;
					if (!altViewHover) {
						// document.body.append(ui.helper[0]);
						altViewHover = true;
						eventElement.draggable('option', 'revert', false);
						eventElement.draggable('option', 'scroll', false);
					}
				} else {
					eventPosition =
						ui.position.top - startScroll + container.scrollTop();
					ui.position.top = eventPosition;
					if (altViewHover) {
						altViewHover = false;
						eventElement.draggable('option', 'scroll', true);
					}
					if (ev.pageY > slotContainerPosition.top) {
						boundaryMode = boundaryModeDragCheck(
							{
								direction: direction,
								allowBoundaryMovement: allowBoundaryMovement,
								ev: ev,
								containerPosition: slotContainerPosition,
								eventElement: eventElement,
								isInBounds: isInBounds,
								prevIsInBounds: prevIsInBounds,
								colWidth: colWidth,
								colCnt: colCnt,
								snapHeight: snapHeight,
							},
							boundaryMode
						);
					}
				}
				prevIsInBounds = isInBounds;
				trigger('eventDrag', eventElement, event, ev, ui);
			},
			stop: function (ev, ui) {
				calendar.reportAltViewDragStop(ui, isAltViewDrag); // For dragging from calendar to unscheduled
				isClone = manageClone(ev, ui, event);
				if (!isClone) {
					eventElement.css('visibility', 'visible');
				}
				hoverListener.stop();
				clearOverlays();
				trigger('eventDragStop', eventElement, event, ev, ui);

				if (
					!isAltViewDrag &&
					(revert || (allDay && !colChanged && !isClone))
				) {
					// hasn't moved or is out of bounds (draggable has already reverted)

					resetElement(ui);
					eventElement.css('filter', ''); // clear IE opacity side-effects
					//Disabled because we aren't hiding events on drag
					//showEvents(event, eventElement);

					eventCancelDrop(this, event, ev, ui);
				} else {
					// changed!
					if (eventResource) {
						//Remove original event resource that we are dragging from and any duplicates
						for (var i = event.resource.length; i >= 0; i--) {
							if (
								event.resource[i] ===
									originalEventResource.name ||
								event.resource[i] === eventResource.name
							) {
								newEventResource.splice(i, 1);
							}
						}
						//Add new event resource that we are dragging to
						newEventResource.push(eventResource.name);
					}

					var eventStart = event.start.clone().add(dayDelta, 'days'); // already assumed to have a stripped time
					var snapTime;
					var snapIndex;
					if (!allDay) {
						snapIndex = Math.round(
							(ui.helper.offset().top -
								getSlotContainer().offset().top) /
								snapHeight
						); // why not use ui.offset.top?
						snapTime = moment.duration(
							minTime + snapIndex * snapDuration
						);
						eventStart = calendar.rezoneDate(
							eventStart.clone().time(snapTime)
						);
					}
					eventDrop(
						this, // el
						event,
						eventStart,
						ev,
						ui,
						{value: newEventResource, field: 'resource'},
						isClone,
						isAltViewDrag
					);
				}
			},
		});
		function resetElement(ui) {
			if (!allDay) {
				ui.helper.width(origWidth).height('');
				eventElement.draggable('option', 'grid', null);
				allDay = true;
			}
		}
	}

	// when event starts out IN TIMESLOTS

	function draggableSlotEvent(event, eventElement, timeElement) {
		var coordinateGrid = t.getCoordinateGrid();
		var colCnt = getColCnt();
		var colWidth = getColWidth();
		var snapHeight = getSnapHeight();
		var snapDuration = getSnapDuration();

		// states
		var origPosition; // original position of the element, not the mouse
		var origCell;
		var isInBounds, prevIsInBounds;
		var isAllDay, prevIsAllDay;
		var colDelta, prevColDelta;
		var dayDelta; // derived from colDelta
		var snapDelta, prevSnapDelta; // the number of snaps away from the original position

		// newly computed
		var eventStart, eventEnd, originalEventResource, eventResource;
		var newEventResource = event.resource.slice(0); //Clone the resource array

		var isClone;

		var startScroll;
		var container = $('.calendar-scroll');
		var containerPosition = container[0].getBoundingClientRect();
		var contentContainer = container[0].querySelector(
			'.fc-event-container'
		);
		var isAltViewDrag;
		var altViewHover;
		var boundaryMode;
		var startPageX;

		eventElement.draggable({
			scroll: false,
			grid: [colWidth, snapHeight],
			axis: colCnt == 1 && resourceDays == 1 ? 'y' : false,
			opacity: opt('dragOpacity'),
			revertDuration: opt('dragRevertDuration'),
			helper: 'clone',
			start: function (ev, ui) {
				if (isPreventEventMovement(event)) {
					eventElement.css('opacity', 1);
					return false;
				}

				isClone = isClone = manageClone(ev, ui, event);
				if (isClone) {
					ui.helper.append(
						'<div class="add-event-button add-event-button-small"></div>'
					);
				} else {
					eventElement.css('visibility', 'hidden');
				}
				timeElement = ui.helper.find('div.fc-event-time');

				startPageX = ev.pageX;
				startScroll = container.scrollTop();
				calendar.reportAltViewDragStart(ev, ui); // For dragging from calendar into unscheduled
				trigger('eventDragStart', eventElement, event, ev, ui);
				//We don't want to hide other instances of the event. Usually this would be repeating events or an event with multiple resources.
				//hideEvents(event, eventElement);

				coordinateGrid.build();

				// initialize states
				origPosition = eventElement.position();
				origCell = coordinateGrid.cell(ev.pageX, ev.pageY);
				isInBounds = prevIsInBounds = true;
				isAllDay = prevIsAllDay = getIsCellAllDay(origCell);
				colDelta = prevColDelta = 0;
				dayDelta = 0;
				snapDelta = prevSnapDelta = 0;
				originalEventResource =
					getResources(false)[origCell.col % colCnt];

				eventStart = null;
				eventEnd = null;
				eventResource = null;
			},
			drag: function (ev, ui) {
				// NOTE: this `cell` value is only useful for determining in-bounds and all-day.
				// Bad for anything else due to the discrepancy between the mouse position and the
				// element position while snapping. (problem revealed in PR #55)
				//
				// PS- the problem exists for draggableDayEvent() when dragging an all-day event to a slot event.
				// We should overhaul the dragging system and stop relying on jQuery UI.
				var cell = coordinateGrid.cell(ev.pageX, ev.pageY);
				var direction = !startPageX
					? 0
					: ev.pageX > startPageX
						? 1
						: -1;
				var allowBoundaryMovement = true;
				ev.pageX - startPageX > colWidth / 3;

				// update states
				isClone = manageClone(ev, ui, event);
				isAltViewDrag = calendar.reportAltViewDrag(
					ev,
					ui,
					contentContainer,
					event
				);
				if (isAltViewDrag) {
					ui.position.top =
						ui.position.top + containerPosition.top - startScroll;
					ui.position.left =
						ui.position.left + containerPosition.left;
					if (!altViewHover) {
						altViewHover = true;
						eventElement.draggable('option', 'revert', false);
						eventElement.draggable('option', 'scroll', false);
						eventElement.draggable('option', 'snap', false);
						eventElement.draggable('option', 'grid', false);
						eventElement.draggable('option', 'axis', false);
					}
				} else {
					if (altViewHover) {
						altViewHover = false;
						boundaryMode = false;
						// if out-of-bounds, revert when done, and vice versa.
						eventElement.draggable('option', 'revert', !isInBounds);
						eventElement.draggable('option', 'grid', true);
						eventElement.draggable(
							'option',
							'axis',
							colCnt == 1 ? 'y' : false
						);
					}
					if (
						!boundaryMode &&
						direction > 0 &&
						allowBoundaryMovement &&
						ev.pageX > containerPosition.right - colWidth / 2
					) {
						boundaryMode = true;
						eventElement.draggable('option', 'grid', [
							1,
							snapHeight,
						]);
						eventElement.draggable('option', 'axis', false);
					} else if (
						boundaryMode &&
						ev.pageX < containerPosition.right - colWidth / 2
					) {
						boundaryMode = false;
						eventElement.draggable('option', 'grid', [
							colWidth,
							snapHeight,
						]);
						eventElement.draggable(
							'option',
							'axis',
							colCnt == 1 ? 'y' : false
						);
					} else if (!isInBounds && prevIsInBounds && colCnt > 1) {
						eventElement.draggable('option', 'grid', [1, 1]);
					} else if (isInBounds && !prevIsInBounds && colCnt > 1) {
						eventElement.draggable('option', 'grid', [
							colWidth,
							snapHeight,
						]);
					}

					isInBounds = !!cell;
					if (isInBounds) {
						isAllDay = getIsCellAllDay(cell);

						// calculate column delta
						colDelta = Math.round(
							(ui.position.left - origPosition.left) / colWidth
						);
						if (colDelta != prevColDelta) {
							// calculate the day delta based off of the original clicked column and the column delta
							// var origDate = cellToDate(0, origCell.col);
							var origDate = cellToDate(
								0,
								Math.floor(origCell.col / colCnt)
							);
							var col = origCell.col + colDelta;
							col = Math.min(colCnt * resourceDays - 1, col);
							var date = cellToDate(0, Math.floor(col / colCnt));
							dayDelta = date.diff(origDate, 'days');
							eventResource = getResources(false)[col % colCnt];
						}

						// calculate minute delta (only if over slots)
						if (!isAllDay) {
							snapDelta = Math.round(
								(ui.position.top - origPosition.top) /
									snapHeight
							);
						}
					}

					// any state changes?
					if (
						isInBounds != prevIsInBounds ||
						isAllDay != prevIsAllDay ||
						colDelta != prevColDelta ||
						snapDelta != prevSnapDelta
					) {
						// compute new dates
						if (isAllDay) {
							eventStart = event.start
								.clone()
								.stripTime()
								.add(dayDelta, 'days');
							eventEnd = eventStart
								.clone()
								.add(calendar.defaultAllDayEventDuration);
						} else {
							// eventStart = event.start.clone().add(snapDelta * snapDuration).add(dayDelta, 'days');
							// eventEnd = getEventEnd(event).add(snapDelta * snapDuration).add(dayDelta, 'days'); //Changed to the method below unzone and rezone date for DST fix

							eventStart = calendar.rezoneDate(
								calendar
									.unzoneDate(event.start)
									.add(snapDelta * snapDuration)
									.add(dayDelta, 'days')
							);
							eventEnd = calendar.rezoneDate(
								calendar
									.unzoneDate(getEventEnd(event))
									.add(snapDelta * snapDuration)
									.add(dayDelta, 'days')
							);
						}

						updateUI(eventResource || originalEventResource);

						// update previous states for next time
						prevIsInBounds = isInBounds;
						prevIsAllDay = isAllDay;
						prevColDelta = colDelta;
						prevSnapDelta = snapDelta;
					}
				}
				// if out-of-bounds, revert when done, and vice versa.
				eventElement.draggable('option', 'revert', !isInBounds);
			},
			stop: function (ev, ui) {
				calendar.reportAltViewDragStop(ui, isAltViewDrag); // For dragging from calendar to unscheduled
				isClone = manageClone(ev, ui, event);
				if (!isClone) {
					eventElement.css('visibility', 'visible');
				}

				clearOverlays();
				if (eventResource) {
					//Remove original event resource that we are dragging from and any duplicates
					for (var i = event.resource.length; i >= 0; i--) {
						if (
							event.resource[i] === originalEventResource.name ||
							event.resource[i] === eventResource.name
						) {
							newEventResource.splice(i, 1);
						}
					}
					//Add new event resource that we are dragging to
					newEventResource.push(eventResource.name);
				}
				trigger('eventDragStop', eventElement, event, ev, ui);

				if (
					isAltViewDrag ||
					(isInBounds &&
						(isAllDay ||
							dayDelta ||
							snapDelta ||
							eventResource ||
							isClone))
				) {
					// changed!

					eventDrop(
						this, // el
						event,
						eventStart,
						ev,
						ui,
						{value: newEventResource, field: 'resource'},
						isClone,
						isAltViewDrag
					);
				} else {
					// either no change or out-of-bounds (draggable has already reverted)

					// reset states for next time, and for updateUI()
					isInBounds = true;
					isAllDay = false;
					colDelta = 0;
					dayDelta = 0;
					snapDelta = 0;

					updateUI(eventResource || originalEventResource);
					eventElement.css('filter', ''); // clear IE opacity side-effects

					// sometimes fast drags make event revert to wrong position, so reset.
					// also, if we dragged the element out of the area because of snapping,
					// but the *mouse* is still in bounds, we need to reset the position.
					eventElement.css(origPosition);
					//Disabled because we aren't hiding events
					//showEvents(event, eventElement);

					eventCancelDrop(this, event, ev, ui);
				}
			},
		});

		function updateUI(eventResource) {
			clearOverlays();
			if (isInBounds) {
				if (isAllDay) {
					timeElement.hide();
					eventElement.draggable('option', 'grid', null); // disable grid snapping
					renderDayOverlay(eventStart, eventEnd, [
						eventResource.name,
					]);
				} else {
					updateTimeText();
					timeElement.css('display', ''); // show() was causing display=inline
					if (!boundaryMode) {
						eventElement.draggable('option', 'grid', [
							colWidth,
							snapHeight,
						]); // re-enable grid snapping
					}
				}
			}
		}

		function updateTimeText() {
			var text;
			if (eventStart) {
				// must of had a state change
				if (event.end) {
					text = formatRange(eventStart, eventEnd, opt('timeFormat'));
				} else {
					text = formatDate(eventStart, opt('timeFormat'));
				}
				timeElement.text(text);
			}
		}
	}

	//What to do if the event can't be dragged
	function nonDraggableDayEvent(event, eventElement) {
		eventElement.draggable({
			start: function (ev, ui) {
				trigger('eventNoDrag', eventElement, event, ev, ui);
				return false;
			},
		});
	}

	/* Resizing
	--------------------------------------------------------------------------------------*/

	function resizableSlotEvent(event, eventElement, timeElement) {
		var snapDelta, prevSnapDelta;
		var snapHeight = getSnapHeight();
		var snapDuration = getSnapDuration();
		let eventStart = event.start.clone();
		let eventEnd = getEventEnd(event);
		let direction;

		//Stop propogation on event mousedown so the event drag isn't called if we don't want it to be
		eventElement.on('mousedown', function (ev) {
			if (isPreventEventMovement(event)) {
				//stop propogation is never available here, disabling, JY
				//ev.stopPropogation();
				return false;
			}
		});

		eventElement.resizable({
			handles: {
				s: '.ui-resizable-s',
				n: '.ui-resizable-n',
			},
			grid: snapHeight,
			start: function (ev, ui) {
				direction = ev.originalEvent.target.classList.contains(
					'ui-resizable-n'
				)
					? 'n'
					: 's';
				snapDelta = prevSnapDelta = 0;
				hideEvents(event, eventElement);
				trigger('eventResizeStart', this, event, ev, ui);
			},
			resize: function (ev, ui) {
				// don't rely on ui.size.height, doesn't take grid into account
				snapDelta = Math.round(
					(Math.max(snapHeight, eventElement.height()) -
						ui.originalSize.height) /
						snapHeight
				);
				if (snapDelta != prevSnapDelta) {
					// eventEnd = getEventEnd(event).add(snapDuration * snapDelta); //Changed to below for DST fix.
					if (direction === 'n') {
						eventStart = calendar
							.unzoneDate(event.start.clone())
							.add(-(snapDuration * snapDelta));
					} else {
						eventEnd = calendar
							.unzoneDate(getEventEnd(event))
							.add(snapDuration * snapDelta);
					}
					//Fix for SeedCode - Added a fix for when a start date and end date are the same. Prevents snap back.
					if (event.start.isSame(event.end) || !event.end) {
						eventEnd.add(snapDuration);
					}
					eventStart = calendar.rezoneDate(eventStart);
					eventEnd = calendar.rezoneDate(eventEnd); //Added for DST fix change above
					var text;
					if (snapDelta || event.end) {
						text = formatRange(
							eventStart,
							eventEnd,
							opt('timeFormat')
						);
					} else {
						text = formatDate(eventStart, opt('timeFormat'));
					}
					timeElement.text(text);
					prevSnapDelta = snapDelta;
				}
			},
			stop: function (ev, ui) {
				trigger('eventResizeStop', this, event, ev, ui);
				if (snapDelta || true) {
					//We want this to always fire otherwise if there was no change in drag we loose the title
					eventResize(this, event, eventEnd, ev, ui, eventStart);
				} else {
					showEvents(event, eventElement);
					// BUG: if event was really short, need to put title back in span
				}
			},
		});
	}
}
